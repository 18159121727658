import { userStore } from '@/store';
import { routeAccessPermissions } from '@/configs/middleware/routeAccessPermissions';
import { IAccessCondition, ICustomAccessCondition } from '@/types/middleware/routeAccessPermissions';

/**
 * ミドルウェア関数
 * 現在のパスに対してログインユーザーが必須権限を持っているかチェック
 *
 * @param {object} context.route 現在のルート
 * @param {object} context.error エラー
 */
export default function ({ route, error }: { route: any; error: any }): void {
  const routeAccessPermissionGuard = new RouteAccessPermissionGuard({ route, error });
  routeAccessPermissionGuard.checkPermissions();
}

/**
 * アクセス権限ガードクラス
 */
class RouteAccessPermissionGuard {
  error: any;
  currentPathPermission: any;
  constructor({ route, error }: { route: any; error: any }) {
    this.error = error;
    this.currentPathPermission = routeAccessPermissions.find(permission => permission.routePath.test(route.path));
  }

  /**
   * 現在のパスに対して権限をチェック
   * 定義がない場合はスキップ
   */
  public checkPermissions(): void {
    const currentPathPermission = this.currentPathPermission;

    if (currentPathPermission && currentPathPermission.item) {
      const item = currentPathPermission.item;

      if (this.isAccessCondition(item)) {
        this.checkAccessCondition(item);
      } else if (this.isCustomAccessCondition(item)) {
        this.checkCustomAccess(item);
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  // アクセス権限のチェック
  // 1. スーパーユーザーの場合は権限チェックをスキップ
  // 2. アクセス権限が定義されていない場合はスキップ
  // 3. ユーザーのもっている権限が配列でない場合はエラー
  // 4. 必要な権限を持っているか確認
  // 5. アクセス可能なユーザータイプであるか確認
  // ------------------------------------------------------------------------------------------------
  /** 型ガード */
  private isAccessCondition(item: IAccessCondition | ICustomAccessCondition): item is IAccessCondition {
    return (item as IAccessCondition).accessCondition !== undefined;
  }

  /** アクセス権限のチェック */
  private checkAccessCondition(item: IAccessCondition) : boolean {
    const loginUser = userStore.loginUser;

    // スーパーユーザーの場合は権限チェックをスキップ
    if (loginUser?.user_is_super_user) {
      return true;
    }

    // アクセス権限が定義されていない場合はスキップ
    if (!this.currentPathPermission) {
      return true;
    }
    // ユーザーのもっている権限
    const hasUserPermissions = userStore.permittedActions;
    if (!Array.isArray(hasUserPermissions)) {
      this.error({
        statusCode: this.currentPathPermission.errorInfo.statusCode,
        message: this.currentPathPermission.errorInfo.errorMessage
      });
      return true;
    }

    // 必要な権限を持っているか確認
    const hasRequiredPermissions = item.accessCondition.permissions.every(
      action => hasUserPermissions.includes(action)
    );

    // アクセス可能なユーザータイプであるか確認
    const isAllowedUserType =
      item.accessCondition.allowedUserTypes.length === 0 ||
      item.accessCondition.allowedUserTypes.includes(loginUser.user_type);

    if (!hasRequiredPermissions || !isAllowedUserType) {
      this.error({
        statusCode: this.currentPathPermission.errorInfo.statusCode,
        message: this.currentPathPermission.errorInfo.errorMessage
      });
    }
    return true;
  }

  // ------------------------------------------------------------------------------------------------
  // カスタムアクセスのチェック
  // - カスタムアクセス関数を実行
  // ------------------------------------------------------------------------------------------------
  /** 型ガード */
  private isCustomAccessCondition(item: IAccessCondition | ICustomAccessCondition): item is ICustomAccessCondition {
    return (item as ICustomAccessCondition).customAccessCondition !== undefined;
  }

  /** カスタムアクセスのチェック */
  private checkCustomAccess(item: ICustomAccessCondition) : void {
    if (!item.customAccessCondition.canAccess()) {
      this.error({
        statusCode: this.currentPathPermission.errorInfo.statusCode,
        message: this.currentPathPermission.errorInfo.errorMessage
      });
    }
  }
}
