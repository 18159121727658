export const RESPONSE_CODE = {
  BAD_REQUEST: {
    statusCode: 400,
    message: 'Bad request'
  },
  UNAUTHORIZED: {
    statusCode: 401,
    message: 'Unauthorized access'
  },
  FORBIDDEN: {
    statusCode: 403,
    message: 'Forbidden'
  },
  NOT_FOUND: {
    statusCode: 404,
    message: 'This page could not be found'
  },
  INTERNAL_SERVER_ERROR: {
    statusCode: 500,
    message: 'Internal server error'
  },
  SERVICE_UNAVAILABLE: {
    statusCode: 503,
    message: 'Service unavailable'
  }
} as const;
