// injectとprovideによる状態共有を行う
// 各コンポーネント間で値を保持する必要がある変数を登録して使う
import { provide, inject, reactive, toRefs } from '@nuxtjs/composition-api';
import { IChatRemark, IRegisterForm } from '@/types';
import {
  getUnreadCmiCount,
  getBachatRooms,
  getChatRooms,
  getBiddingCategories,
  getChatCpr,
  getCmc,
  getNoreplyChatCount,
  getClosedInquiryCount,
  getOpenSC,
  getMatchingReceivedHistory,
  postInitBuiltinChatRooms
} from '@/apis';
import { xbaApis } from '@/apis/xba';
import { IAichatBaCompanyNeedsWritingStyleResponse } from '@/types/xba';

import { BREADCRUMB } from '@/configs';
import { BUNDLE_PAYMENT_PLAN_ID, BundlePayment } from '@/types/payment';

export const key = Symbol('key');

interface State {
  windowWidth: number | null;
  windowHeight: number | null;
  globalNavi: boolean;
  isAdmin: boolean;
  switchingUser: boolean;
  overlay: boolean;
  breadcrumb: any;
  searchCondition: { url: string; values: any };
  paginationInfo: {
    totalPages: number;
    totalRows: number;
    currentPage: number;
  };
  toastMessages: any;
  isMobile: boolean;
  device: string;
  mobileNavi: { isShow: boolean, title: string };
  showFooter: boolean;
  unreadCmiCount: number; // 配信未読数（gnav_badge_news）
  bachatRoomsUnreadCount: number; // 金融機関連絡チャット未読数（bachat_rooms_unread_count）
  chatRoomsUnreadCount: number; // ビジネスチャット未読数(chat_rooms_unread_count),
  matchingUnrepliedCount: number; // 商談依頼未返信数(cmh_unreplied_count),
  biddingCategories: string[]; // 入札種別
  registerForm: IRegisterForm;
  registerBaPage: string;
  bachatRooms: any;
  bachatRemarkForms: IChatRemark[];
  chatRemarksActiveMenu: string;
  unapprovedChatCount: number; // 未承認チャット件数
  noreplyChatCount: number; // 未返信件数
  closedInquiryCount: number; // お問い合わせ件数
  openSC: any; // 安否確認
  needChatroomReload: boolean; // ビジネスチャットのルーム更新要否
  callProcessEvent: boolean; // ビジネスチャットのショートポーリングで処理が必要かどうか
  eiEventCode: string; // ショートポーリングイベントコード
  eiData: any; // ショートポーリングイベントデータ
  yappliRegisteredId: string; // yappli用デバッグ情報
  checkedIds: string[]; // チェックボックスデータ
  extarnalLink: boolean; // 外部リンク許可
  icnextDisplayModal: boolean; // icnext_display_modal
  bundlePayment: BundlePayment;
  aichatBaCompanyNeedsWritingStyle: IAichatBaCompanyNeedsWritingStyleResponse; // ニーズアシスト用の文書スタイル
}

export const createStore = (): any => {
  const state = reactive<State>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    globalNavi: true,
    isAdmin: false,
    switchingUser: false,
    overlay: false,
    breadcrumb: {
      pathname: '',
      title: '',
      subTitle: '',
      links: [{ text: '', href: '' }]
    },
    searchCondition: {
      url: '',
      values: {}
    },
    paginationInfo: {
      totalPages: 0,
      totalRows: 0,
      currentPage: 1
    },
    toastMessages: {
      success: {
        is_show: false,
        message: ''
      },
      error: {
        is_show: false,
        message: ''
      }
    },
    isMobile: false,
    device: 'pc',
    mobileNavi: { isShow: false, title: '' },
    showFooter: true,
    unreadCmiCount: 0,
    bachatRoomsUnreadCount: 0,
    chatRoomsUnreadCount: 0,
    matchingUnrepliedCount: 0,
    biddingCategories: [],
    registerForm: {
      user_last_name: '',
      user_first_name: '',
      user_last_name_kana: '',
      user_first_name_kana: '',
      user_email: '',
      confirm_user_email: '',
      user_tel: '',
      company_corporate_number: '',
      company_name: '',
      company_name_kana: '',
      company_tel: '',
      company_rep_name: '',
      company_rep_birthday: '',
      company_establish_date: '',
      company_account_closing_month: null,
      company_employee_count: 0,
      company_website: '',
      company_address: {
        main: {
          post_code_1: '',
          post_code_2: '',
          pref_name: '',
          address_1: '',
          address_2: ''
        },
        branch: [
          {
            post_code_1: '',
            post_code_2: '',
            pref_name: '',
            address_1: '',
            address_2: ''
          }
        ]
      },
      large_classification: '',
      middle_classification: '',
      company_small_business_type: '',
      company_has_no_bank_branch: 0,
      company_bb_id: 0,
      company_bank_account_type: '',
      company_bank_account_number: 0,
      company_identification_file: null,
      company_is_other_manager: 0,
      company_other_managers: '',
      company_interesting_features: [],
      cc_token: '',
      cc_sp_masked_card_number: '',
      agree1: false,
      agree1_unixtime: null,
      company_check_anti_social: 0,
      agree2: false,
      bundle_payment_plan_id: BUNDLE_PAYMENT_PLAN_ID.NONE,
      agree_bundle_payment: false
    },
    registerBaPage: 'index',
    bachatRooms: {},
    bachatRemarkForms: [],
    chatRemarksActiveMenu: '',
    unapprovedChatCount: 0,
    noreplyChatCount: 0,
    closedInquiryCount: 0,
    openSC: null,
    needChatroomReload: false,
    callProcessEvent: false,
    eiEventCode: '',
    eiData: {},
    yappliRegisteredId: '',
    checkedIds: [],
    extarnalLink: true,
    icnextDisplayModal: true,
    bundlePayment: {
      isShow: false,
      plans: [],
      campaign: null
    },
    aichatBaCompanyNeedsWritingStyle: []
  });

  const onResize = (): void => {
    state.isMobile = window.innerWidth < 600;
    if (window.innerWidth > 1024) {
      state.device = 'pc';
      // デフォルトでメニューを表示
      state.mobileNavi.isShow = false;
    } else if (window.innerWidth > 480 && window.innerWidth <= 1024) {
      state.device = 'tablet';
      // デフォルトでメニューを表示しない
      state.mobileNavi.isShow = true;
    } else {
      state.device = 'sp';
    }
  };

  // フッター表示設定
  const setFooter = (show: boolean): void => {
    state.showFooter = show;
  };

  // 管理者設定
  const setAdmin = (isAdmin: boolean): void => {
    state.isAdmin = isAdmin;
  };

  // 管理ユーザー切替状態設定
  const changeUser = (status: boolean): void => {
    state.switchingUser = status;
  };

  // オーバーレイ設定
  const setOverlay = (overlay: boolean): void => {
    state.overlay = overlay;
  };

  // グローバルメニューの表示切替
  const showGlobalNavi = (show: boolean): void => {
    state.globalNavi = show;
  };

  // カレントページのパンくず情報設定
  const setBreadcrumb = (path: string, withoutId = false, isRegex = false): void => {
    if (path !== '/' && path.substr(path.length - 1, 1) === '/') {
      path = path.substr(0, path.length - 1);
    }

    if (withoutId) {
      const pathArray = path.split('/');
      pathArray.pop();
      path = pathArray.join('/');
    }

    if (isRegex) {
      state.breadcrumb = BREADCRUMB.find((item) => {
        const reg = new RegExp(item.pathname);
        return reg.test(path);
      });
    } else {
      state.breadcrumb = BREADCRUMB.find((item) => item.pathname === path);
    }
  };

  // 一覧の検索条件を保持
  const setConditions = <T extends {}>(url: string, form: T): void => {
    state.searchCondition.url = url;
    state.searchCondition.values = form;
  };

  // 一覧のページ情報
  const setPagenationInfo = (
    totalPages: number,
    totalRows: number,
    currentPage: number
  ): void => {
    state.paginationInfo.totalPages = totalPages;
    state.paginationInfo.totalRows = totalRows;
    state.paginationInfo.currentPage = currentPage;
  };

  // 未承認チャット件数設定
  const setunapprovedChatCount = async (): Promise<void> => {
    let cnt = 0;
    const data = await getChatCpr();
    if (data && data.result && data.result.paging_data) {
      cnt = data.result.paging_data.total_rows;

      const cmc = await getCmc();
      if (cmc && cmc.result) {
        cnt += cmc.result.paging_data.total_rows;
        state.unapprovedChatCount = cnt;
      }
    }
  };

  // 未返信件数設定
  const setNoreplyChatCount = async (): Promise<void> => {
    const data = await getNoreplyChatCount();
    if (data && data.result) {
      state.noreplyChatCount = data.result.count;
    }
  };

  // お問い合わせ件数設定
  const setClosedInquiryCount = async (): Promise<void> => {
    const data = await getClosedInquiryCount();
    if (data && data.result) {
      state.closedInquiryCount = data.result.count;
    }
  };

  // 安否確認
  const setOpenSC = async (): Promise<void> => {
    const data = await getOpenSC();
    if (data && data.result) {
      state.openSC = data.result.survey;
    }
  };

  // yappli用デバッグ情報
  const setYappliInfo = (yappliRegisteredId: string): void => {
    state.yappliRegisteredId = yappliRegisteredId;
  };

  // チェックボックスの値設定
  const setCheckedId = (id: string, add: boolean): void => {
    if (add) {
      state.checkedIds.push(id);
    } else {
      state.checkedIds = state.checkedIds.filter((val) => val !== id);
    }
  };

  // チェックボックスの値クリア
  const resetCheckedId = (): void => {
    state.checkedIds = [];
  };

  // storeとローカルストレージの値を設定
  const setIcnextModal = (show: boolean): void => {
    state.icnextDisplayModal = show;
    localStorage.setItem('icnext_display_modal', show.toString());
  };

  // トーストメッセージ設定
  const addToastMessage = (message: any, type?: string): void => {
    const t = type ?? 'success';
    const obj: any = { ...state.toastMessages[t] };
    let msg = 'エラーが発生しました';
    if (typeof message === 'object' && message.body) {
      msg = message.body;
    }
    if (typeof message === 'string') {
      msg = message;
    }
    obj[msg] = true;
    state.toastMessages[t] = obj;
  };

  // メニュー用通知用各種件数
  const countNotifications = (): Promise<boolean> => {
    const promiseUnreadCmiCount: any = getUnreadCmiCount(); // 未読の配信数
    const promiseBachatRooms: any = getBachatRooms(); // 金融機関連絡チャットリスト
    const promiseChatRooms: any = getChatRooms(); // ビジネスチャットリスト
    const promiseMatchingReceivedHistory: any = getMatchingReceivedHistory(); // ビジネスチャットリスト

    return Promise.all([
      promiseUnreadCmiCount,
      promiseBachatRooms,
      promiseChatRooms,
      promiseMatchingReceivedHistory
    ]).then(([unreadCmiCount, bachatRooms, chatRooms, matchingReceivedHistory]) => {
      if (unreadCmiCount && unreadCmiCount.result) {
        state.unreadCmiCount = unreadCmiCount.result.paging_data.total_rows;
      }
      let cntBachat = 0;
      if (bachatRooms && bachatRooms.result.bachat_rooms) {
        for (const item in bachatRooms.result.bachat_rooms) {
          cntBachat +=
            bachatRooms.result.bachat_rooms[`${item}`]
              .bachatroom_has_company_unread_remark || 0;
        }
        state.bachatRooms = bachatRooms.result;
        state.bachatRoomsUnreadCount = cntBachat;
      }

      let cntChat = 0;
      state.chatRoomsUnreadCount = cntChat;
      if (chatRooms && chatRooms.result && chatRooms.result.chat_rooms) {
        for (const item in chatRooms.result.chat_rooms) {
          cntChat += chatRooms.result.chat_rooms[`${item}`].unread_count || 0;
        }
        state.chatRoomsUnreadCount = cntChat;
      }

      let unrepliedCount = 0;
      if (matchingReceivedHistory && matchingReceivedHistory.result && matchingReceivedHistory.result.list) {
        for (const key in matchingReceivedHistory.result.list) {
          unrepliedCount += matchingReceivedHistory.result.list[key].cmh_unreplied_count;
        }
        state.matchingUnrepliedCount = unrepliedCount;
      }

      return true;
    });
  };

  // ご案内の未読数
  const loadGnavBadgeNews = (): Promise<boolean> => {
    const promiseUnreadCmiCount: any = getUnreadCmiCount(); // 未読の配信数

    return Promise.all([promiseUnreadCmiCount]).then(([unreadCmiCount]) => {
      if (unreadCmiCount && unreadCmiCount.result) {
        state.unreadCmiCount = unreadCmiCount.result.paging_data.total_rows;
      }
      return true;
    });
  };

  // セレクトボックス用データ
  const forSelectItems = (): Promise<boolean> => {
    const promiseBiddingCategories: any = getBiddingCategories(); // 入札種別

    return Promise.all([promiseBiddingCategories]).then(
      ([biddingCategories]) => {
        if (biddingCategories && biddingCategories.result) {
          state.biddingCategories = biddingCategories.result.list;
          return false;
        }
        return true;
      }
    );
  };

  // 保存用企業登録formデータ初期化
  const initRegisterForm = (): void => {
    state.registerForm = {
      user_last_name: '',
      user_first_name: '',
      user_last_name_kana: '',
      user_first_name_kana: '',
      user_email: '',
      confirm_user_email: '',
      user_tel: '',
      company_corporate_number: '',
      company_name: '',
      company_name_kana: '',
      company_tel: '',
      company_rep_name: '',
      company_rep_birthday: '',
      company_establish_date: '',
      company_account_closing_month: null,
      company_employee_count: 0,
      company_website: '',
      company_address: {
        main: {
          post_code_1: '',
          post_code_2: '',
          pref_name: '',
          address_1: '',
          address_2: ''
        },
        branch: [
          {
            post_code_1: '',
            post_code_2: '',
            pref_name: '',
            address_1: '',
            address_2: ''
          }
        ]
      },
      large_classification: '',
      middle_classification: '',
      company_small_business_type: '',
      company_has_no_bank_branch: 0,
      company_bb_id: 0,
      company_bank_account_type: '',
      company_bank_account_number: 0,
      company_identification_file: null,
      company_is_other_manager: 0,
      company_other_managers: '',
      company_interesting_features: [],
      cc_token: '',
      cc_sp_masked_card_number: '',
      agree1: false,
      agree1_unixtime: null,
      company_check_anti_social: 0,
      agree2: false,
      bundle_payment_plan_id: BUNDLE_PAYMENT_PLAN_ID.NONE,
      agree_bundle_payment: false
    };
  };

  // 保存用企業登録formデータにデータ追加
  const addRegisterForm = (form: IRegisterForm): void => {
    initRegisterForm();
    state.registerForm = form;
  };

  // register/baのページ用
  const addRegisterBaPage = (page: string): void => {
    state.registerBaPage = page;
  };

  const initBuiltinChatRooms = (): void => {
    postInitBuiltinChatRooms();
  };

  const setBundlePayment = (value: BundlePayment): void => {
    state.bundlePayment = value;
  };

  const setAichatBaCompanyNeedsWritingStyle = (): void => {
    const { getAichatBaCompanyNeedsWritingStyle } = xbaApis();
    getAichatBaCompanyNeedsWritingStyle().then((data: IAichatBaCompanyNeedsWritingStyleResponse) => {
      state.aichatBaCompanyNeedsWritingStyle = data;
    });
  };

  return {
    onResize,
    showGlobalNavi,
    setConditions,
    setPagenationInfo,
    setFooter,
    setAdmin,
    changeUser,
    setOverlay,
    setBreadcrumb,
    setunapprovedChatCount,
    setNoreplyChatCount,
    setClosedInquiryCount,
    setOpenSC,
    setYappliInfo,
    setCheckedId,
    resetCheckedId,
    setIcnextModal,
    addToastMessage,
    countNotifications,
    loadGnavBadgeNews,
    forSelectItems,
    initRegisterForm,
    addRegisterForm,
    addRegisterBaPage,
    initBuiltinChatRooms,
    setBundlePayment,
    setAichatBaCompanyNeedsWritingStyle,
    ...toRefs(state)
  };
};

export const provideStore = (): any => {
  provide(key, createStore());
};

export const useStore = (): any => {
  return inject(key);
};
