export { default as XBA } from './xba';

/**
 * サイドナビメニュー項目
 */
export type NavigationItem = {
  title: string;
  icon: {
    default: string;
    selected: string;
  };
  path: string; // メインのパス
  related_path?: string[]; // その他関連付けたいパス
  user_type?: string[];
  required?: string[];
  user_type_exclude?: boolean;
  company_type?: string;
  sub?: NavigationItem[];
  hash?: string; // 同一名、同一パスのメニューがある場合はURLにハッシュをつけて判別する
}

/**
 * ホーム
 */
export const HOME_MENU: NavigationItem = {
  title: 'ホーム',
  icon: {
    default: 'mdi-home-outline',
    selected: 'mdi-home'
  },
  path: '/mypage/dashboard',
  sub: [
    {
      title: '安否確認',
      icon: {
        default: 'mdi-account-heart-outline',
        selected: 'mdi-account-heart'
      },
      path: '/mypage/sc',
      required: ['company_survey_read']
    },
    {
      title: 'トップページ',
      icon: {
        default: 'mdi-view-dashboard-outline',
        selected: 'mdi-view-dashboard'
      },
      path: '/mypage/dashboard'
    },
    {
      title: 'ご案内/お知らせ',
      icon: {
        default: 'mdi-bullhorn-outline',
        selected: 'mdi-bullhorn'
      },
      path: '/mypage/news',
      user_type: ['company_owner', 'company_user', 'partner_company_owner'],
      required: ['company_news_read']
    },
    {
      title: 'お知らせ',
      icon: {
        default: 'mdi-bullhorn-outline',
        selected: 'mdi-bullhorn'
      },
      path: '/mypage/news',
      required: ['company_news_read'],
      user_type_exclude: true,
      user_type: ['company_owner', 'company_user', 'partner_company_owner']
    },
    {
      title: '金融機関連絡チャット',
      icon: {
        default: 'mdi-bank-outline',
        selected: 'mdi-bank'
      },
      path: '/mypage/bachat',
      required: ['company_bachat_read']
    },
    {
      title: 'ビジネスチャット',
      icon: {
        default: 'mdi-chat-outline',
        selected: 'mdi-chat'
      },
      path: '/chat',
      required: ['company_chat_read']
    },
    {
      title: 'ホームページ管理',
      icon: {
        default: 'mdi-monitor',
        selected: 'mdi-monitor'
      },
      path: '/mypage/setting/website',
      required: ['company_setting_website_write']
    },
    {
      title: '補助金・助成金',
      icon: {
        default: 'mdi-hand-coin-outline',
        selected: 'mdi-hand-coin'
      },
      path: '/mypage/subsidy',
      required: ['company_subsidy_read']
    },
    {
      title: '補助金申請支援サービス',
      icon: {
        default: 'mdi-text-box-edit-outline',
        selected: 'mdi-text-box-edit'
      },
      path: '/mypage/subsidy/applysupport',
      user_type: ['company_owner', 'company_user', 'partner_company_owner']
    },
    {
      title: '入札情報',
      icon: {
        default: 'mdi-gavel',
        selected: 'mdi-gavel'
      },
      path: '/mypage/bidding',
      required: ['company_bidding_read']
    },
    {
      title: '経営相談掲示板',
      icon: {
        default: 'mdi-chat-question-outline',
        selected: 'mdi-chat-question'
      },
      path: '/mypage/forum',
      user_type: ['company_owner', 'partner_company_owner']
    },
    {
      title: 'お得な外部サービス',
      icon: {
        default: 'mdi-puzzle-outline',
        selected: 'mdi-puzzle'
      },
      path: '/mypage/mall',
      user_type: ['company_owner', 'partner_company_owner', 'company_user']
    },
    {
      title: 'セミナー',
      icon: {
        default: 'mdi-school-outline',
        selected: 'mdi-school'
      },
      path: '/mypage/seminar',
      required: ['company_seminar_read']
    },
    {
      title: '経営コラム',
      icon: {
        default: 'mdi-office-building-outline',
        selected: 'mdi-office-building'
      },
      path: '/mypage/column',
      required: ['company_column_read']
    },
    {
      title: '業界別メディア一覧',
      icon: {
        default: 'mdi-view-list-outline',
        selected: 'mdi-view-list'
      },
      path: '/mypage/trend',
      required: ['company_trend_read']
    },
    {
      title: '事業承継のご相談',
      icon: {
        default: 'mdi-account-tie-outline',
        selected: 'mdi-account-tie'
      },
      path: '/mypage/succession',
      required: ['company_succession_write']
    },
    {
      title: '経営資料一覧',
      icon: {
        default: 'mdi-file-download-outline',
        selected: 'mdi-file-download'
      },
      path: '/mypage/support/document',
      required: ['company_support_document_read']
    },
    {
      title: '安否確認',
      icon: {
        default: 'mdi-account-heart-outline',
        selected: 'mdi-account-heart'
      },
      path: '/mypage/sc/admin',
      required: ['company_survey_write']
    }
  ]
};

/**
 * マッチング
 */
export const MATTING_MENU: NavigationItem = {
  title: 'マッチング',
  icon: {
    default: 'mdi-handshake-outline',
    selected: 'mdi-handshake'
  },
  path: '/mypage/matching/search',
  required: ['company_matching_read'],
  sub: [
    {
      title: 'ニーズ検索',
      icon: {
        default: 'mdi-text-box-search-outline',
        selected: 'mdi-text-box-search'
      },
      path: '/mypage/matching/search',
      required: [
        'company_setting_matching_write',
        'company_setting_company_write'
      ]
    },
    {
      title: 'ニーズ登録',
      icon: {
        default: 'mdi-text-box-edit-outline',
        selected: 'mdi-text-box-edit'
      },
      path: '/mypage/mybusiness',
      required: ['company_matching_write']
    },
    {
      title: '商談管理',
      icon: {
        default: 'mdi-briefcase-clock-outline',
        selected: 'mdi-briefcase-clock'
      },
      path: '/mypage/matching/received_history',
      required: ['company_matching_read']
    },
    {
      title: '商談申込履歴',
      icon: {
        default: 'mdi-clipboard-text-clock-outline',
        selected: 'mdi-clipboard-text-clock'
      },
      path: '/mypage/matching/history',
      required: ['company_matching_read']
    },
    {
      title: 'お気に入りニーズ',
      icon: {
        default: 'mdi-heart-box-outline',
        selected: 'mdi-heart-box'
      },
      path: '/mypage/matching/favca',
      required: ['company_matching_write']
    },
    {
      title: 'ニーズ通知設定',
      icon: {
        default: 'mdi-bell-cog-outline',
        selected: 'mdi-bell-cog'
      },
      path: '/mypage/setting/matching/search'
    },
    {
      title: '会社紹介ページ(自社)',
      icon: {
        default: 'mdi-office-building-outline',
        selected: 'mdi-office-building'
      },
      path: '/mypage/matching/company/'
    }
  ]
};

/**
 * 福利厚生（書込権限用）
 */
export const FUKURI_WRITE_MENU: NavigationItem = {
  title: '福利厚生',
  icon: {
    default: 'mdi-ticket-confirmation-outline',
    selected: 'mdi-ticket-confirmation'
  },
  path: '/mypage/setting/service/offer',
  required: ['company_setting_coupon_write'],
  hash: '#fukuri',
  sub: [
    {
      title: 'クーポン登録',
      icon: {
        default: 'mdi-store-edit-outline',
        selected: 'mdi-store-edit'
      },
      path: '/mypage/setting/service/offer',
      required: ['company_setting_coupon_write']
    },
    {
      title: 'クーポン利用',
      icon: {
        default: 'mdi-ticket-outline',
        selected: 'mdi-ticket'
      },
      path: '/fukuri',
      required: ['company_coupon_read']
    },
    {
      title: '従業員アカウント設定',
      icon: {
        default: 'mdi-account-multiple-plus-outline',
        selected: 'mdi-account-multiple-plus'
      },
      path: '/mypage/setting/account#fukuri', // 設定にも同項目があり識別のためハッシュを付与
      related_path: ['/mypage/setting/account/employee/'],
      required: ['company_setting_account_write']
    }
  ]
};

/**
 * 福利厚生（読込権限用）
 */
export const FUKURI_READ_MENU: NavigationItem = {
  title: '福利厚生',
  icon: {
    default: 'mdi-ticket-confirmation-outline',
    selected: 'mdi-ticket-confirmation'
  },
  required: ['company_coupon_read'],
  path: '/fukuri'
};

/**
 * オープンイノベーション（パートナー企業）
 */
export const OPEN_INNOVATION_PARTNER_MENU: NavigationItem = {
  title: 'オープンイノベーション',
  icon: {
    default: 'mdi-asterisk',
    selected: 'mdi-asterisk'
  },
  path: '/mypage/innovation',
  required: ['company_innovation_read'],
  company_type: 'partner',
  sub: [
    {
      title: '募集中の企画一覧',
      icon: {
        default: 'mdi-view-grid-outline',
        selected: 'mdi-view-grid'
      },
      path: '/mypage/innovation',
      required: ['company_innovation_read']
    },
    {
      title: '企画を登録',
      icon: {
        default: 'mdi-text-box-edit-outline',
        selected: 'mdi-text-box-edit'
      },
      path: '/mypage/setting/innovation',
      required: ['company_setting_innovation_write']
    }
  ]
};

/**
 * オープンイノベーション（通常企業）
 */
export const OPEN_INNOVATION_MENU: NavigationItem = {
  title: 'オープンイノベーション',
  icon: {
    default: 'mdi-asterisk',
    selected: 'mdi-asterisk'
  },
  path: '/mypage/innovation',
  required: ['company_innovation_read'],
  company_type: '!partner',
  sub: [
    {
      title: '募集中の企画一覧',
      icon: {
        default: 'mdi-view-grid-outline',
        selected: 'mdi-view-grid'
      },
      path: '/mypage/innovation',
      required: ['company_innovation_read']
    }
  ]
};

/**
 * クラウドファウンディング
 */
export const CLOUD_FUNDING_MENU: NavigationItem = {
  title: 'クラウドファウンディング',
  icon: {
    default: 'mdi-account-group-outline',
    selected: 'mdi-account-group'
  },
  path: '/mypage/crowdfunding/campfire',
  required: ['company_campfire_list_read'],
  sub: [
    {
      title: 'CAMPFIREログイン/会員登録',
      icon: {
        default: 'mdi-fire',
        selected: 'mdi-fire'
      },
      path: '/mypage/crowdfunding/campfire',
      required: ['company_campfire_login_read']
    },
    {
      title: 'CAMPFIREプロジェクト',
      icon: {
        default: 'mdi-view-grid-outline',
        selected: 'mdi-view-grid'
      },
      path: '/mypage/crowdfunding/campfire/list',
      related_path: [
        '/mypage/crowdfunding/campfire/bank/list',
        '/mypage/crowdfunding/campfire/all/list'
      ],
      required: ['company_campfire_list_read']
    }
  ]
};

/**
 * ちゃんと請求書
 */
export const PAYMENT_MENU: NavigationItem = {
  title: 'ちゃんと請求書',
  icon: {
    default: 'mdi-receipt-text-outline',
    selected: 'mdi-receipt-text'
  },
  path: '/payment',
  user_type: ['company_owner', 'company_user'],
  company_type: '!partner'
};

/**
 * 勤怠サービス オーナーメニュー
 */
export const CHANTO_ATTENDANCE_OWNER_MENU: NavigationItem = {
  title: '勤怠サービス',
  icon: {
    default: 'mdi-clock-outline',
    selected: 'mdi-clock'
  },
  path: '/mypage/chanto-attendance/top',
  user_type: ['company_owner', 'partner_company_owner'],
  sub: [
    {
      title: 'サービス紹介と利用登録',
      icon: {
        default: 'mdi-clock-check-outline',
        selected: 'mdi-clock-check'
      },
      path: '/mypage/chanto-attendance/top',
      user_type: ['company_owner', 'partner_company_owner']
    },
    {
      title: 'ユーザー登録',
      icon: {
        default: 'mdi-account-plus-outline',
        selected: 'mdi-account-plus'
      },
      path: '/mypage/chanto-attendance/registration',
      user_type: ['company_owner', 'partner_company_owner']
    },
    {
      title: 'タイムカード・経費精算',
      icon: {
        default: 'mdi-page-next-outline',
        selected: 'mdi-page-next'
      },
      path: '/mypage/chanto-attendance/application',
      user_type: ['company_owner', 'partner_company_owner']
    }
  ]
};

/**
 * 勤怠サービス 従業員ーメニュー
 */
export const CHANTO_ATTENDANCE_EMPLOYEE_MENU: NavigationItem = {
  title: '勤怠サービス',
  icon: {
    default: 'mdi-clock-time-four-outline',
    selected: 'mdi-clock-time-four'
  },
  path: '/mypage/chanto-attendance/application',
  user_type: ['company_user', 'company_employee']
};

/**
 * 事業承継
 */
export const SUCCESSION_MENU: NavigationItem = {
  title: '事業承継',
  icon: {
    default: 'mdi-office-building-outline',
    selected: 'mdi-office-building'
  },
  path: '/mypage/business/succession/column',
  user_type: ['company_owner', 'partner_company_owner', 'company_user'],
  sub: [
    {
      title: '事業承継コラム',
      icon: {
        default: 'mdi-newspaper-variant-outline',
        selected: 'mdi-newspaper-variant'
      },
      path: '/mypage/business/succession/column'
    },
    {
      title: '事業承継方針の検討パターン',
      icon: {
        default: 'mdi-file-tree-outline',
        selected: 'mdi-file-tree'
      },
      path: '/mypage/business/succession/faq'
    },
    {
      title: '株価試算',
      icon: {
        default: 'mdi-calculator-variant-outline',
        selected: 'mdi-calculator-variant'
      },
      path: '/mypage/business/succession/calculation/stock'
    },
    {
      title: '相続税試算',
      icon: {
        default: 'mdi-account-arrow-right-outline',
        selected: 'mdi-account-arrow-right'
      },
      path: '/mypage/business/succession/calculation/tax'
    }
  ]
};

/**
 * 連携金融機関一覧
 */
export const BA_BANK_MENU: NavigationItem = {
  title: '連携金融機関一覧',
  icon: {
    default: 'mdi-bank-check',
    selected: 'mdi-bank-check'
  },
  path: '/mypage/babank',
  required: ['company_babank_read'],
  sub: [
    {
      title: '連携金融機関一覧',
      icon: {
        default: 'mdi-bank-check',
        selected: 'mdi-bank-check'
      },
      path: '/mypage/babank',
      required: ['company_babank_read']
    }
  ]
};

/**
 * 設定
 */
export const SETTING_MENU: NavigationItem = {
  title: '設定',
  icon: {
    default: 'mdi-cog-outline',
    selected: 'mdi-cog'
  },
  path: '/mypage/setting/profile',
  hash: '#setting',
  sub: [
    {
      title: 'プロフィール編集',
      icon: {
        default: 'mdi-account-edit-outline',
        selected: 'mdi-account-edit'
      },
      path: '/mypage/setting/profile'
    },
    {
      title: '企業情報編集',
      icon: {
        default: 'mdi-office-building-cog-outline',
        selected: 'mdi-office-building-cog'
      },
      path: '/mypage/company/edit',
      required: ['company_setting_company_write']
    },
    {
      title: '情報配信設定',
      icon: {
        default: 'mdi-bell-cog-outline',
        selected: 'mdi-bell-cog'
      },
      path: '/mypage/setting/information',
      required: ['company_setting_partner_information_write']
    },
    {
      title: 'ビジネスユーザー設定',
      icon: {
        default: 'mdi-account-cog-outline',
        selected: 'mdi-account-cog'
      },
      path: '/mypage/setting/account/businessuser',
      required: ['company_setting_businessuser_write']
    },
    {
      title: '従業員アカウント設定',
      icon: {
        default: 'mdi-account-multiple-plus-outline',
        selected: 'mdi-account-multiple-plus'
      },
      path: '/mypage/setting/account#setting', // 福利厚生にも同項目があり識別のためハッシュを付与
      related_path: ['/mypage/setting/account/employee/'],
      required: ['company_setting_account_write']
    },
    {
      title: 'ご利用明細(インボイス)',
      icon: {
        default: 'mdi-receipt-text-outline',
        selected: 'mdi-receipt-text'
      },
      path: '/mypage/setting/invoice',
      user_type: ['company_owner', 'company_user'],
      company_type: '!partner'
    },
    {
      title: 'クレジットカード登録',
      icon: {
        default: 'mdi-credit-card-plus-outline',
        selected: 'mdi-credit-card-plus'
      },
      path: '/mypage/payment/card/create',
      required: ['company_setting_payment_write']
    },
    {
      title: 'チャット定型文',
      icon: {
        default: 'mdi-chat-processing-outline',
        selected: 'mdi-chat-processing'
      },
      path: '/mypage/setting/chat_remark_template',
      required: ['company_chat_read']
    },
    {
      title: 'パスワード設定',
      icon: {
        default: 'mdi-lock-outline',
        selected: 'mdi-lock'
      },
      path: '/mypage/setting/password'
    }
  ]
};

/**
 * グローバルメニュー
 */
export const GLOBAL_MENU: NavigationItem[] = [
  HOME_MENU,
  MATTING_MENU,
  FUKURI_WRITE_MENU,
  FUKURI_READ_MENU,
  OPEN_INNOVATION_PARTNER_MENU,
  OPEN_INNOVATION_MENU,
  CLOUD_FUNDING_MENU,
  PAYMENT_MENU,
  CHANTO_ATTENDANCE_OWNER_MENU,
  CHANTO_ATTENDANCE_EMPLOYEE_MENU,
  SUCCESSION_MENU,
  BA_BANK_MENU,
  SETTING_MENU
];

/**
 *パンくずデータJSONデータ
 *
 * @description セレクトボックスで利用
 */
export const BREADCRUMB = [
  {
    pathname: '/mypage/dashboard',
    title: 'ダッシュボード',
    subTitle: 'Dashbord'
  },
  {
    pathname: '/mypage/forum',
    title: '経営相談掲示板',
    subTitle: 'forum',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '経営相談掲示板', href: '' }
    ]
  },
  {
    pathname: '/mypage/chanto-attendance/top',
    title: '勤怠連携サービス',
    subTitle: 'Attendance Service',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'サービス紹介と利用登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/chanto-attendance/registration',
    title: '勤怠連携サービス',
    subTitle: 'Attendance Service',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ユーザー登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/forum/detail',
    title: '経営相談掲示板',
    subTitle: 'forum',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '経営相談掲示板', href: '/mypage/forum' },
      { text: '相談詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/forum/list',
    title: '経営相談掲示板',
    subTitle: 'forum',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '経営相談掲示板', href: '/mypage/forum' },
      { text: '相談一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/bachat',
    title: '金融機関連絡チャット',
    subTitle: 'BA Chat'
  },
  {
    pathname: '/mypage/chat',
    title: 'チャット',
    subTitle: 'Chat'
  },
  {
    pathname: '/mypage/matching',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ニーズ検索', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/campaign',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'マッチングプロジェクト', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/search',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ニーズ検索', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/search/detail',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ニーズ検索', href: '/mypage/matching/search' },
      { text: 'ニーズ詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/company',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '会社紹介ページ', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/favca',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お気に入りニーズ', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/received_history',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '商談管理', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/received_history/detail',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '商談管理', href: '/mypage/matching/received_history' },
      { text: '商談依頼詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/matching/history',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '商談申込履歴', href: '' }
    ]
  },
  {
    pathname: '/mypage/mybusiness/needs/create',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ビジネスマッチング', href: '' },
      { text: 'ニーズ新規登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/mybusiness/needs/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ビジネスマッチング', href: '' },
      { text: 'ニーズ編集', href: '' }
    ]
  },
  {
    pathname: '/mypage/mybusiness/edit',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ビジネスマッチング', href: '' },
      { text: '基本情報設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/mybusiness',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ビジネスマッチング', href: '' },
      { text: 'ニーズ設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/matching/search',
    title: 'ビジネスマッチング',
    subTitle: 'Matching',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'マッチングニーズ通知設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/mall',
    title: 'お得な外部サービス',
    subTitle: 'Big Advance Mall',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お得な外部サービス', href: '' }
    ]
  },
  {
    pathname: '/mypage/mall/policy',
    title: 'お得な外部サービス',
    subTitle: 'Big Advance Mall',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お得な外部サービス', href: '' }
    ]
  },
  {
    pathname: '/mypage/mall/detail',
    title: 'お得な外部サービス',
    subTitle: 'Big Advance Mall',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お得な外部サービス', href: '/mypage/mall' },
      { text: '詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/seminar',
    title: 'セミナー',
    subTitle: 'Seminar',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'セミナー一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/seminar/detail',
    title: 'セミナー',
    subTitle: 'Seminar',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'セミナー一覧', href: '/mypage/seminar' },
      { text: 'セミナー詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/service/offer',
    title: 'クーポン管理',
    subTitle: 'Coupon',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'クーポン管理', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/service/offer/edit',
    title: 'クーポン管理',
    subTitle: 'Coupon',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'クーポン管理', href: '/mypage/setting/service/offer' },
      { text: 'クーポン編集', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/website',
    title: 'ホームページ管理',
    subTitle: 'Homepage',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ホームページ管理', href: '' }
    ]
  },
  {
    pathname: '/mypage/subsidy',
    title: '補助金・助成金',
    subTitle: 'Subsidy',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '補助金・助成金検索', href: '' }
    ]
  },
  {
    pathname: '/mypage/subsidy/search',
    title: '検索結果',
    subTitle: 'Search result',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '補助金・助成金検索', href: '/mypage/subsidy' },
      { text: '検索結果', href: '' }
    ]
  },
  {
    pathname: '/mypage/subsidy/detail',
    title: '補助金・助成金',
    subTitle: 'Subsidy',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '補助金・助成金検索', href: '/mypage/subsidy' },
      { text: '検索結果', href: '/mypage/subsidy/search' },
      { text: '詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/subsidy/applysupport',
    title: '補助金申請支援サービス',
    subTitle: 'Subsidy service',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '補助金申請支援サービス', href: '' }
    ]
  },
  {
    pathname: '/mypage/bidding',
    title: '入札情報',
    subTitle: 'Bidding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '入札情報', href: '' }
    ]
  },
  {
    pathname: '/mypage/bidding/detail',
    title: '入札情報',
    subTitle: 'Bidding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '入札情報', href: '/mypage/bidding' },
      { text: '詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/column',
    title: '経営コラム',
    subTitle: 'Column',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '経営コラム', href: '' }
    ]
  },
  {
    pathname: '/mypage/column/detail',
    title: '経営コラム',
    subTitle: 'Column',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '経営コラム一覧', href: '/mypage/column' },
      { text: '経営コラム', href: '' }
    ]
  },
  {
    pathname: '/mypage/trend',
    title: '業界別メディア一覧',
    subTitle: 'Media',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '業界別メディア一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/innovation',
    title: 'オープンイノベーション',
    subTitle: 'Open innovation',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'オープンイノベーション', href: '' }
    ]
  },
  {
    pathname: '/mypage/innovation/detail',
    title: 'オープンイノベーション',
    subTitle: 'Open innovation',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'オープンイノベーション', href: '/mypage/innovation' },
      { text: '詳細', href: '' }
    ]
  },
  {
    pathname: '/mypage/sc',
    title: '安否確認',
    subTitle: 'SurveyTop',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '安否確認', href: '' }
    ]
  },
  {
    pathname: '/mypage/sc/admin',
    title: '安否確認管理画面',
    subTitle: 'SurveyAdminTop',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '安否確認管理画面', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/profile',
    title: 'プロフィール設定',
    subTitle: 'Company info',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'プロフィール設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/company/edit',
    title: '企業情報編集',
    subTitle: 'Profile setting',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '企業情報編集', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/invoice',
    title: 'ご利用明細(インボイス)',
    subTitle: 'Payment History',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ご利用明細(インボイス)', href: '' }
    ]
  },
  {
    pathname: '/mypage/payment/card/create',
    title: 'クレジットカード登録',
    subTitle: 'Payment Information',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'クレジットカード登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/chat_remark_template',
    title: 'チャット定型文',
    subTitle: 'Chat template',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'チャット定型文', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/chat_remark_template/edit',
    title: 'チャット定型文',
    subTitle: 'Chat template',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      {
        text: 'チャット定型文',
        href: '/mypage/setting/chat_remark_template'
      },
      { text: '登録／編集', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/password',
    title: 'パスワード設定',
    subTitle: 'Password setting',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'パスワード設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/website',
    title: 'ホームページ管理',
    subTitle: 'Homepage',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ホームページ管理', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/information',
    title: '情報配信',
    subTitle: 'Confirm',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '情報配信', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/innovation',
    title: 'オープンイノベーション',
    subTitle: 'Open innovation',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'オープンイノベーション', href: '/mypage/innovation' },
      { text: '企画一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/innovation/edit',
    title: 'オープンイノベーション',
    subTitle: 'Open innovation',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'オープンイノベーション', href: '/mypage/innovation' },
      { text: '企画一覧', href: '/mypage/setting/innovation' },
      { text: '登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/news',
    title: 'お知らせ',
    subTitle: 'News',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お知らせ', href: '' }
    ]
  },
  {
    pathname: '/news',
    title: 'お知らせ',
    subTitle: 'News',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'お知らせ', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/unsubscribe',
    title: '退会確認',
    subTitle: 'Confirm',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '退会確認', href: '' }
    ]
  },
  {
    pathname: '/mypage/succession',
    title: '事業承継',
    subTitle: 'Succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '事業承継のご相談', href: '' }
    ]
  },
  {
    pathname: '/mypage/support/manual',
    title: '使い方マニュアル',
    subTitle: 'Manual',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '使い方マニュアル', href: '' }
    ]
  },
  {
    pathname: '/mypage/support/document',
    title: '資料ダウンロード',
    subTitle: 'Document',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '資料ダウンロード', href: '' }
    ]
  },
  {
    pathname: '/mypage/babank',
    title: '金融機関一覧',
    subTitle: 'Chat',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '金融機関一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/account/businessuser',
    title: 'ビジネスユーザー設定',
    subTitle: 'Business user account',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'ビジネスユーザー設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/account',
    title: '従業員アカウント設定',
    subTitle: 'Employee account',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '従業員アカウント設定', href: '' }
    ]
  },
  {
    pathname: '/mypage/setting/account/employee',
    title: '従業員アカウント設定',
    subTitle: 'Employee account',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '従業員アカウント設定', href: '/mypage/setting/account' },
      { text: '従業員アカウント登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/pi/detail',
    title: 'パートナー配信情報',
    subTitle: 'PR',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'パートナー配信情報', href: '' }
    ]
  },
  {
    pathname: '/mypage/crowdfunding/campfire',
    title: 'クラウドファンディング',
    subTitle: 'Crowdfunding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'CAMPFIRE ログイン/会員登録', href: '' }
    ]
  },
  {
    pathname: '/mypage/crowdfunding/campfire/list',
    title: 'クラウドファンディング',
    subTitle: 'Crowdfunding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: 'CAMPFIRE プロジェクト一覧', href: '' }
    ]
  },
  {
    pathname: '/mypage/crowdfunding/campfire/all/list',
    title: 'クラウドファンディング',
    subTitle: 'Crowdfunding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      {
        text: 'CAMPFIRE プロジェクト一覧',
        href: '/mypage/crowdfunding/campfire/list'
      },
      { text: '全国のプロジェクト一覧' }
    ]
  },
  {
    pathname: '/mypage/crowdfunding/campfire/bank/list',
    title: 'クラウドファンディング',
    subTitle: 'Crowdfunding',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      {
        text: 'CAMPFIRE プロジェクト一覧',
        href: '/mypage/crowdfunding/campfire/list'
      },
      { text: '[bank_name]のプロジェクト一覧' }
    ]
  },
  {
    pathname: '/mypage/business/succession/column',
    title: '事業承継',
    subTitle: 'Business succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '事業承継コラム', href: '' }
    ]
  },
  {
    pathname: '/mypage/business/succession/column/detail',
    title: '事業承継',
    subTitle: 'Business succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      {
        text: '事業承継コラム一覧',
        href: '/mypage/business/succession/column'
      },
      { text: '事業承継コラム', href: '' }
    ]
  },
  {
    pathname: '/mypage/business/succession/faq',
    title: '事業承継',
    subTitle: 'Business succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '事業承継方針の検討パターン', href: '' }
    ]
  },
  {
    pathname: '/mypage/business/succession/calculation/stock',
    title: '事業承継',
    subTitle: 'Business succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '株価試算', href: '' }
    ]
  },
  {
    pathname: '/mypage/business/succession/calculation/tax',
    title: '事業承継',
    subTitle: 'Business succession',
    links: [
      { text: 'トップ', href: '/mypage/dashboard' },
      { text: '相続税試算', href: '' }
    ]
  }
];

/**
 * 市区町村データJSONデータ
 *
 * @description セレクトボックスで利用
 */
export const PREFECTURE = [
  {
    value: '北海道',
    text: '北海道'
  },
  {
    value: '青森県',
    text: '青森県'
  },
  {
    value: '岩手県',
    text: '岩手県'
  },
  {
    value: '宮城県',
    text: '宮城県'
  },
  {
    value: '秋田県',
    text: '秋田県'
  },
  {
    value: '山形県',
    text: '山形県'
  },
  {
    value: '福島県',
    text: '福島県'
  },
  {
    value: '茨城県',
    text: '茨城県'
  },
  {
    value: '栃木県',
    text: '栃木県'
  },
  {
    value: '群馬県',
    text: '群馬県'
  },
  {
    value: '埼玉県',
    text: '埼玉県'
  },
  {
    value: '千葉県',
    text: '千葉県'
  },
  {
    value: '東京都',
    text: '東京都'
  },
  {
    value: '神奈川県',
    text: '神奈川県'
  },
  {
    value: '新潟県',
    text: '新潟県'
  },
  {
    value: '富山県',
    text: '富山県'
  },
  {
    value: '石川県',
    text: '石川県'
  },
  {
    value: '福井県',
    text: '福井県'
  },
  {
    value: '山梨県',
    text: '山梨県'
  },
  {
    value: '長野県',
    text: '長野県'
  },
  {
    value: '岐阜県',
    text: '岐阜県'
  },
  {
    value: '静岡県',
    text: '静岡県'
  },
  {
    value: '愛知県',
    text: '愛知県'
  },
  {
    value: '三重県',
    text: '三重県'
  },
  {
    value: '滋賀県',
    text: '滋賀県'
  },
  {
    value: '京都府',
    text: '京都府'
  },
  {
    value: '大阪府',
    text: '大阪府'
  },
  {
    value: '兵庫県',
    text: '兵庫県'
  },
  {
    value: '奈良県',
    text: '奈良県'
  },
  {
    value: '和歌山県',
    text: '和歌山県'
  },
  {
    value: '鳥取県',
    text: '鳥取県'
  },
  {
    value: '島根県',
    text: '島根県'
  },
  {
    value: '岡山県',
    text: '岡山県'
  },
  {
    value: '広島県',
    text: '広島県'
  },
  {
    value: '山口県',
    text: '山口県'
  },
  {
    value: '徳島県',
    text: '徳島県'
  },
  {
    value: '香川県',
    text: '香川県'
  },
  {
    value: '愛媛県',
    text: '愛媛県'
  },
  {
    value: '高知県',
    text: '高知県'
  },
  {
    value: '福岡県',
    text: '福岡県'
  },
  {
    value: '佐賀県',
    text: '佐賀県'
  },
  {
    value: '長崎県',
    text: '長崎県'
  },
  {
    value: '熊本県',
    text: '熊本県'
  },
  {
    value: '大分県',
    text: '大分県'
  },
  {
    value: '宮崎県',
    text: '宮崎県'
  },
  {
    value: '鹿児島県',
    text: '鹿児島県'
  },
  {
    value: '沖縄県',
    text: '沖縄県'
  }
];

/**
 * 公開/非公開データJSONデータ
 *
 * @description セレクトボックスで利用
 */
export const PUBLISHED_STATUS = [
  {
    value: '1',
    text: '公開'
  },
  {
    value: '0',
    text: '非公開'
  }
];

/**
 * PR動画での公開/非公開データJSONデータ
 * PUBLISHED_STATUS(公開/非公開データ)と 0/1 が逆なのでご注意ください
 *
 * @description セレクトボックスで利用
 */
export const PROMOTION_PUBLISHED_STATUS = [
  {
    value: '0',
    text: '公開'
  },
  {
    value: '1',
    text: '非公開'
  }
];

/**
 * パートナーの検索条件設定JSONデータ
 *
 * @description セレクトボックスで利用
 */
export const IS_PARTNER = [
  {
    value: '1',
    text: 'パートナー企業のみを検索対象にする'
  },
  {
    value: '2',
    text: 'パートナー企業以外を検索対象にする'
  }
];

/**
 * データ状態
 *
 * @description セレクトボックスで利用
 */
export const DATA_ENABLE = [
  {
    value: '0',
    text: '有効'
  },
  {
    value: '1',
    text: '無効'
  }
];

/**
 * メルマガ配信状況のJSONデータ
 *
 * @description セレクトボックスで利用
 */
export const SENT_STATUS = [
  {
    value: 'DRAFT',
    text: '未配信'
  },
  {
    value: 'READY',
    text: '配信予約済み'
  },
  {
    value: 'SENT',
    text: '配信済み'
  }
] as const;

/**
 * パートナー情報発信状態
 *
 * @description セレクトボックスで利用
 */
export const PI_STATUS = [
  {
    value: 'REVIEWING',
    text: '申請中'
  },
  {
    value: 'BEFORE_PUBLISHING',
    text: '配信待ち'
  },
  {
    value: 'PUBLISHING',
    text: '配信中'
  },
  {
    value: 'AFTER_PUBLISHING',
    text: '配信済み'
  },
  {
    value: 'DISAPPROVAL',
    text: '不承認'
  },
  {
    value: 'CANCELLED',
    text: 'キャンセル'
  }
];

/**
 * 未対応/対応中/解決済データJSONデータ
 *
 * @description セレクトボックスで利用
 */
export const INQUIRY_STATUS = [
  {
    value: 'CREATED',
    text: '未対応'
  },
  {
    value: 'PROGRESS',
    text: '対応中'
  },
  {
    value: 'CLOSED',
    text: '解決済'
  }
];

/**
 * バナー配信有無
 *
 * @description セレクトボックスで利用
 */
export const BANNER_DELIVERY = [
  {
    value: '1',
    text: 'する'
  },
  {
    value: '0',
    text: 'しない'
  }
];

/**
 * 権限
 *
 * @description 権限定義
 */
export const PERMISSION = {
  '/mypage/bachat': {
    kind: 'required',
    permissions: ['company_chat_read'],
    label: 'ビジネスチャット'
  },
  '/mypage/matching': {
    kind: 'required',
    permissions: ['company_chat_read'],
    label: 'ビジネスチャット'
  }
};

/**
 * 対応・未対応
 *
 * @description セレクトボックスで利用
 */
export const SUPPORTED_STATUS = [
  {
    value: '1',
    text: '未対応'
  },
  {
    value: '2',
    text: '対応'
  }
];

/**
 * 月別サイト実績ソートタイプ
 *
 * @description セレクトボックスで利用
 */
export const PERFORMANCE_MONTHLY_SORT_TYPE = [
  {
    value: 'cal_year',
    text: '対象年'
  },
  {
    value: 'cal_month',
    text: '対象月'
  },
  {
    value: 'monthly_general_company_count',
    text: '新規一般企業数'
  },
  {
    value: 'monthly_partner_company_count',
    text: '新規パートナー企業数'
  },
  {
    value: 'monthly_general_user_count',
    text: '新規一般企業従業員数'
  },
  {
    value: 'monthly_partner_user_count',
    text: '新規パートナー企業従業員数'
  },
  {
    value: 'monthly_general_matching_count',
    text: '新規一般企業ニーズ数'
  },
  {
    value: 'monthly_partner_matching_count',
    text: '新規パートナー企業ニーズ数'
  },
  {
    value: 'monthly_total_cso_count',
    text: '新規クーポン数'
  },
  {
    value: 'monthly_total_user_login_count',
    text: 'アクティブユーザ'
  },
  {
    value: 'monthly_active_percent',
    text: 'アクティブ率'
  }
];

/**
 * 支店別サイト実績ソートタイプ
 *
 * @description セレクトボックスで利用
 */
export const PERFORMANCE_BRANCH_SORT_TYPE = [
  {
    value: 'enable_company_count',
    text: '登録企業総数'
  },
  {
    value: 'total_mathing_count',
    text: 'ニーズ数'
  },
  {
    value: 'regist_matching_company_count',
    text: 'ニーズ登録企業数'
  },
  {
    value: 'regist_mathing_percentage',
    text: 'ニーズ登録率'
  },
  {
    value: 'mathing_per_count',
    text: '1社あたりのニーズ登録数'
  },
  {
    value: 'history_count',
    text: '商談依頼数（依頼元）'
  },
  {
    value: 'company_employee_count',
    text: '従業員数'
  },
  {
    value: 'service_offer_count',
    text: 'FUKURI登録数'
  },
  {
    value: 'chatremark_count',
    text: 'チャットコメント数'
  }
];

/**
 * ソートタイプ
 *
 * @description セレクトボックスで利用
 */
export const SORT_TYPE = [
  {
    value: '0',
    text: '昇順'
  },
  {
    value: '1',
    text: '降順'
  }
];

/**
 * 銀行タイプ選択
 *
 * @description セレクトボックスで利用
 */
export const BANK_TYPE_SELECT = [
  {
    value: '',
    text: '全部'
  },
  {
    value: '1',
    text: '自行'
  },
  {
    value: '0',
    text: '他行'
  }
];

/**
 * あり/なし_選択
 *
 * @description セレクトボックスで利用
 */
export const EXIST_FILE = [
  {
    value: '1',
    text: 'あり'
  },
  {
    value: '2',
    text: 'なし'
  }
];

/**
 * あり/なし_選択
 *
 * @description セレクトボックスで利用
 */
export const EXIST_OR_NOTEXIST = [
  {
    value: '1',
    text: 'あり'
  },
  {
    value: '0',
    text: 'なし'
  }
];

/**
 * クーポン利用タイプ
 *
 * @description セレクトボックスで利用
 */
export const CSO_TYPE = [
  {
    value: '',
    text: ''
  },
  {
    value: 1,
    text: '法人向け'
  },
  {
    value: 2,
    text: '個人向け'
  }
];

/**
 * 表示非表示汎用
 *
 * @description セレクトボックスで利用
 */
export const DATA_SHOW = [
  {
    value: '0',
    text: '非表示'
  },
  {
    value: '1',
    text: '表示'
  }
];

/*
 * イベントタイプ
 *
 * @description セレクトボックスで利用
 */
export const EVENT_TYPE = [
  {
    value: 'bank',
    text: 'bank'
  },
  {
    value: 'bank_branch',
    text: 'bank_branch'
  },
  {
    value: 'user',
    text: 'user'
  },
  {
    value: 'email',
    text: 'email'
  },
  {
    value: 'chatwork',
    text: 'chatwork'
  },
  {
    value: 'yappli_notification',
    text: 'yappli_notification'
  }
];

/**
 * 課金種別
 *
 * @description セレクトボックスで利用
 */
export const CHARGE_TYPE = [
  {
    value: 'subscription',
    text: 'サブスクリプション'
  },
  {
    value: 'adjust',
    text: '調整'
  },
  {
    value: 'pay_per_use',
    text: '従量課金'
  }
];

export const NOTE_TYPE = Object.freeze({
  TYPE_MEMO: 'MEMO',
  TYPE_ACTION: 'ACTION'
});

export const NOTE_TARGET = Object.freeze({
  TARGET_MATCHING: 'MATCHING',
  TARGET_INNOVATION: 'INNOVATION',
  TARGET_ADVERTISEMENT: 'ADVERTISEMENT',
  TARGET_COMPANY: 'COMPANY',
  TARGET_COMPANY_REGISTER: 'COMPANY_REGISTER',
  TARGET_INQUIRY: 'INQUIRY'
});

/*
 * イベントステータス
 *
 * @description セレクトボックスで利用
 */
export const EVENT_STATUS = [
  { value: 1, text: '配信済み' },
  { value: 2, text: '承認済み' },
  { value: 3, text: '未承認' },
  { value: 4, text: '却下' }
];

/**
 * @type string
 * @description テスト環境判定のバーを表示しない環境
 */
export const EXCLUSION_TEST_BAR = 'PRODUCTION';

/*
 * TOPICステータス
 *
 * @topicテーブルstatus
 */
export const TOPIC_STATUS = {
  CREATE: 1,
  DRAFT: 2,
  CLOSE: 3,
  DELETE: 4,
  PENDING: 5
};

/*
 * ウェブサイトテーマカラー
 *
 * @description セレクトボックスで利用
 */
export const THEME_COLORS = [
  {
    value: 1,
    text: 'オーシャン',
    main: '#445C66',
    catch: '#DB9281',
    menu: '#FFF8F3'
  },
  {
    value: 2,
    text: 'スカイ',
    main: '#E1F0F4',
    catch: '#002849',
    menu: '#002849'
  },
  {
    value: 3,
    text: 'オータム',
    main: '#D8D2C2',
    catch: '#B17457',
    menu: '#4A4947'
  },
  {
    value: 4,
    text: 'フォレスト',
    main: '#2D5248',
    menu: '#FFFFFF',
    catch: '#E46C37'
  }
];

/*
 * 環境相違により画像が取得できない場合に返却するダミー画像のパス
 */
export const DUMMY_IMG = '/assets/img/s3_dummy.png';

/*
 * 事前入力項目 ニーズ区分
 *
 * @description セレクトボックスで利用
 */
export const BM_NEEDS_TYPE = [
  {
    value: '売却',
    text: '売却'
  },
  {
    value: '購入',
    text: '購入'
  },
  {
    value: '賃貸',
    text: '賃貸'
  },
  {
    value: '賃貸',
    text: '賃貸'
  },
  {
    value: '貸借',
    text: '貸借'
  },
  {
    value: '建築',
    text: '建築'
  }
];

/**
 * ユーザータイプの型
 */
interface IUserType {
  OWNER: string;
  PARTNER_OWNER: string;
  USER: string;
  EMPLOYEE: string;
}

/**
 * ユーザーのタイプ
 */
export const USER_TYPE: IUserType = {
  /** 企業オーナー */
  OWNER: 'company_owner',
  /** パートナー企業オーナー */
  PARTNER_OWNER: 'partner_company_owner',
  /** ビジネスユーザー */
  USER: 'company_user',
  /** 企業従業員 */
  EMPLOYEE: 'company_employee'
};

/**
 * 企業タイプ
 */
export const COMPANY_TYPE = {
  NORMAL: 'normal',
  /** パートナー企業 */
  PARTNER: 'partner',
  /** BAポータル企業 */
  BA_PORTAL: 'baportal'
};

export const S3_PATHS = {
  SP_CONTENTS_PICKUP_PAGE: '/sp_contents/pickup/index.html',
  SP_CONTENTS_GUIDANCE_PAGE: '/sp_contents/guidance/tebiki.html'
};

/**
 * Pendoの参照クラス
 */
export const PENDO_REF_CLASS = {
  CREATE_NEEDS_BUTTON: 'pendo-cc3a30a8-ed97-bd02-30b9-a0524966e354'
};
