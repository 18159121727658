import { canCompanyBulkCreate } from '@/utils/conditions';
import { IAccessPermission } from '@/types/middleware/routeAccessPermissions';
import { OPERATION_PERMISSIONS } from '@/configs/enums/permissions';
import { RESPONSE_CODE } from '@/configs/http';

/** アクセス権限の定義 */
export const routeAccessPermissions: IAccessPermission[] = [
  {
    /**
     * マッチング検索
     * - /mypage/matching/search で始まる場合
     */
    routePath: /^\/mypage\/matching\/search/,
    item: {
      accessCondition: {
        permissions: [
          OPERATION_PERMISSIONS.COMPANY_SETTING_MATCHING_WRITE,
          OPERATION_PERMISSIONS.COMPANY_SETTING_COMPANY_WRITE,
          OPERATION_PERMISSIONS.COMPANY_MATCHING_READ
        ],
        allowedUserTypes: []
      }
    },
    errorInfo: {
      statusCode: 403,
      errorMessage: '権限がありません'
    }
  },
  {
    /**
     * 企業一括作成
     * - /admin/company/bulk-create
     * - /admin/company/bulk-create/
     */
    routePath: /^\/admin\/company\/bulk-create\/?$/,
    item: {
      customAccessCondition: {
        canAccess: canCompanyBulkCreate
      }
    },
    errorInfo: {
      statusCode: RESPONSE_CODE.NOT_FOUND.statusCode,
      errorMessage: RESPONSE_CODE.NOT_FOUND.message
    }
  },
  {
    /**
     * 商談管理
     * - /mypage/matching/received_history で始まる場合
     */
    routePath: /^\/mypage\/matching\/received_history/,
    item: {
      accessCondition: {
        permissions: [
          OPERATION_PERMISSIONS.COMPANY_MATCHING_READ
        ],
        allowedUserTypes: []
      }
    },
    errorInfo: {
      statusCode: 403,
      errorMessage: '権限がありません'
    }
  }
];
