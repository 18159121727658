




















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UserProfile',
  props: {
    companyName: {
      type: String,
      default: null
    },
    userLastName: {
      type: String,
      default: null
    },
    userFirstName: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    }
  }
});
