
































































































































































import {
  defineComponent,
  onMounted,
  useContext,
  ref,
  useRouter,
  computed
} from '@nuxtjs/composition-api';
import { useStore } from '@/compositions/store';
import { appStore, userStore } from '@/store';
import { testBar } from '@/utils';
import UserProfile from '@/components/layout/user-profile.vue';
import ApplicationBarToggleButton from '@/components/layout/applicationbar-toggle-button.vue';
import NavigationMainButton from '@/components/layout/navigation-main-button.vue';

export default defineComponent({
  name: 'ApplicationBar',
  components: { NavigationMainButton, ApplicationBarToggleButton, UserProfile },
  props: {
    hideLogoutButton: {
      type: Boolean,
      require: false,
      default: false
    },
    badgeCount: {
      type: Number,
      default: undefined
    },
    // ユーザー情報の表示
    showUserProfile: {
      type: Boolean,
      require: false,
      default: true
    },
    // メニューのトグルボタンの表示
    showMenu: {
      type: Boolean,
      require: false,
      default: true
    },
    // ヘルプボタンの表示
    showHelpButton: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  setup(_, { emit }) {
    const { isAdmin, onResize, isMobile, device, settingDrawer } = useStore();
    const ajustment = testBar() && !appStore.appConfig.is_yappli_client
      ? 'height: 54px; top:12px'
      : 'height: 54px; top:0';

    const loggedIn = ref<boolean>(false);
    const switched = ref<boolean>(false);

    const ctx = useContext();
    loggedIn.value = ctx.$auth.loggedIn;

    if (localStorage.getItem('user_switch_from')) {
      switched.value = true;
    }

    const profileData = computed(() => ({
      userLastName: userStore.loginUser.user_last_name ?? '',
      userFirstName: userStore.loginUser.user_first_name ?? '',
      companyName: userStore.loginUser.company_name ?? '',
      imageUrl: userStore.loginUser.company_logo_file ? `${ctx.$fileBaseUrl}/picture/bank/${userStore.loginUser.company_logo_file.file_unique_code}` : ''
    }));

    onMounted(() => {
      onResize();
    });

    // サイドメニュー表示・非表示
    const toggleMenu = (): void => {
      emit('toggle');
    };

    // 切替解除
    const router = useRouter();
    const cancelSwitching = (): void => {
      router.push('/login');
    };

    /**
     * ヘルプページを開く
     */
    const openHelp = (): void => {
      window.open('/mypage/help', '_blank');
    };

    return {
      appStore,
      userStore,
      isAdmin,
      isMobile,
      onResize,
      loggedIn,
      switched,
      cancelSwitching,
      ajustment,
      testBar,
      toggleMenu,
      device,
      settingDrawer,
      openHelp,
      profileData
    };
  }
});
