import { useRouter } from '@nuxtjs/composition-api';
import { Save } from '@/compositions/base-save';
import { IAdminCompanyregisterForm } from '@/types';
import { IAdminHgbaPortalCompanyregisterForm, IBaHgbaPortalForm } from '@/types/portal/hgba';
import { ISettingPermissions } from '@/types/api/xba';
import { useStore } from '@/compositions/store';
import {
  ICompanyCreateBulkForm,
  ICompanyCreateAllRequest,
  IAichatBaCompanyNeedsRequestBody,
  IAichatBaCompanyNeedsResponse,
  IAichatBaCompanyNeedsWritingStyleResponse
} from '@/types/xba';
import request from '@/utils/api';
import { Search } from '@/compositions/base-search';

type IXbaApis = {
  postXbaCompanyRegisterApproved: (form: IAdminCompanyregisterForm) => Promise<void>;
  postXbaCompanyRegisterRejected: (form: IAdminCompanyregisterForm) => Promise<void>;
  postHgbaPortalCompanyRegisterRejected: (hgbaPortalForm: IAdminHgbaPortalCompanyregisterForm) => Promise<void>;
  postXbaCompanyUnsubscribe: (form: any) => Promise<void>;
  postHgbaPortalOnlyUnsubscribe: (form: any) => Promise<void>;
  postCompanyCreateAll: (form: ICompanyCreateBulkForm) => Promise<void>;
  getXbaSettingPermissions: () => Promise<ISettingPermissions>;
  postAichatBaCompanyNeeds: (form: IAichatBaCompanyNeedsRequestBody) => Promise<IAichatBaCompanyNeedsResponse>;
  getAichatBaCompanyNeedsWritingStyle: () => Promise<IAichatBaCompanyNeedsWritingStyleResponse>;
  getXbaUpgradeStatus: () => Promise<any>;
  postHgbaCompanyRegisterUpgrade: (form: IBaHgbaPortalForm) => Promise<'credit_payment' | 'normal' | ''>;
}
/**
 * XBA APIs
 */
export const xbaApis = ():IXbaApis => {
  // 登録共通モジュールを利用
  const { saveData, setSaveConfig, saveError, error } = Save();
  const { addToastMessage, setOverlay } = useStore();
  const router = useRouter();
  const { setSearchConfig, loadData } = Search();

  const postXbaCompanyRegisterApproved = async (form: IAdminCompanyregisterForm): Promise<void> => {
    setSaveConfig('/api/v1/companyregister/approved', {
      success: '申請を承認しました',
      error: '申請の承認に失敗しました',
      useResponseMessage: true,
      doToast: true
    });
    try {
      await saveData({ data: form });
    } catch (e) {
      throw new Error('申請の承認に失敗しました');
    }
    if (saveError.value && error.value.body) {
      error.value = error.value.body;
    }
  };

  const postXbaCompanyRegisterRejected = async (form: IAdminCompanyregisterForm): Promise<void> => {
    setSaveConfig('/api/v1/companyregister/rejected', {
      success: '申請を却下しました',
      error: '申請の却下に失敗しました',
      useResponseMessage: true,
      doToast: true
    });
    try {
      await saveData({ data: form });
    } catch (e) {
      throw new Error('申請の却下に失敗しました');
    }
    if (saveError.value) {
      throw new Error(error.value.body);
    }
  };

  const postHgbaPortalCompanyRegisterRejected = async (hgbaPortalForm: IAdminHgbaPortalCompanyregisterForm): Promise<void> => {
    setSaveConfig('/api/v1/hgba/portal/companyregister/rejected', {
      success: '申請を却下しました',
      error: '申請の却下に失敗しました',
      useResponseMessage: true,
      doToast: true
    });
    try {
      await saveData({ data: hgbaPortalForm });
    } catch (e) {
      throw new Error('申請の却下に失敗しました');
    }
    if (saveError.value) {
      throw new Error(error.value.body);
    }
  };

  /**
   * BAの退会予約
   */
  const postXbaCompanyUnsubscribe = async (form: any): Promise<void> => {
    setSaveConfig('/api/v1/company/unsubscribe', {
      success: '退会予約を行いました',
      error: '退会予約に失敗しました',
      useResponseMessage: true,
      doToast: true
    });
    const r: any = await saveData({ data: form });
    if (r && r.status && r.status === 'error') {
      const message = r.status === 'error' ? '退会予約に失敗しました' : 'エラーが発生しました';
      addToastMessage(message, 'error');
      throw new Error(message);
    }
  };

  /**
   * 肥後銀行のポータルにしか加入していない場合の退会予約
   */
  const postHgbaPortalOnlyUnsubscribe = async (form: any): Promise<void> => {
    setSaveConfig('/api/v1/hgba/portal/company/unsubscribe', {
      success: '退会予約を行いました',
      error: '退会予約に失敗しました',
      useResponseMessage: true,
      doToast: true
    });
    const r: any = await saveData({ data: form });
    if (r && r.status && r.status === 'error') {
      const message = r.status === 'error' ? '退会予約に失敗しました' : 'エラーが発生しました';
      addToastMessage(message, 'error');
      throw new Error(message);
    }
  };

  /**
   * 企業一括登録用のAPI
   */
  const postCompanyCreateAll = async (form: ICompanyCreateBulkForm): Promise<void> => {
    const endpoint = '/api/v1/company/create_all';

    setOverlay(true);

    try {
      const response: any = await request({
        url: endpoint,
        method: 'post',
        data: form as ICompanyCreateAllRequest
      });

      if (response?.result === null) {
        addToastMessage('保存しました');
        router.push('/admin/company');
        return;
      }

      if (response?.result?.status === 'error') {
        addToastMessage('保存に失敗しました', 'error');
        return;
      }

      addToastMessage('エラーが発生しました', 'error');
    } catch (error) {
      addToastMessage('エラーが発生しました', 'error');
    } finally {
      setOverlay(false);
    }
  };

  /**
   * 全ユーザータイプの権限設定取得
   */
  const getXbaSettingPermissions = async (): Promise<ISettingPermissions> => {
    // 権限データ取得API
    const permissionEndpoint = '/api/v1/setting/permissions';
    setSearchConfig(permissionEndpoint, {
      method: 'get',
      ignorePageing: true
    });
    const result: ISettingPermissions = await loadData();
    return result;
  };

  /**
   * AIアシストニーズ生成API
   */
  const postAichatBaCompanyNeeds = async (form: IAichatBaCompanyNeedsRequestBody): Promise<IAichatBaCompanyNeedsResponse> => {
    const endpoint = '/api/v1/aichat/ba/company/needs';

    try {
      const response: any = await request({
        url: endpoint,
        method: 'post',
        data: form as IAichatBaCompanyNeedsRequestBody
      });

      if (!response || !response.result || response.status !== 'success') {
        throw new Error('AIアシストニーズ生成APIのレスポンスが不正です。');
      }

      const data = response.result as IAichatBaCompanyNeedsResponse;
      return data;
    } catch (error) {
      console.error('AIアシストニーズ生成に失敗しました。', error);
      throw new Error('エラーが発生しました');
    }
  };

  /**
   * writing_style取得API
   */
  const getAichatBaCompanyNeedsWritingStyle = async (): Promise<IAichatBaCompanyNeedsWritingStyleResponse> => {
    const endpoint = 'api/v1/aichat/ba/company/needs/writing_styles';

    try {
      const response: any = await request({
        url: endpoint,
        method: 'get'
      });

      if (!response || response.status !== 'success') {
        throw new Error('writing_style取得APIのレスポンスが不正です。');
      }

      const data = response.result as IAichatBaCompanyNeedsWritingStyleResponse;
      return data;
    } catch (error) {
      console.error('writing_style取得APIの取得に失敗しました。', error);
      throw new Error('エラーが発生しました');
    }
  };

  /**
   * アップグレードステータス取得
   */
  const getXbaUpgradeStatus = async (): Promise<any> => {
    setSearchConfig('/api/v1/companyregister/upgrade_status', {
      ignorePageing: true,
      method: 'get'
    });
    const r: any = await loadData();
    if (r && r.status && r.status === 'error') {
      const message = 'エラーが発生しました';
      addToastMessage(message, 'error');
      throw new Error(message);
    }
    return r;
  };

  /**
   * 肥銀ポータルからBA会員へアップグレード
   */
  const postHgbaCompanyRegisterUpgrade = async (form: IBaHgbaPortalForm): Promise<'credit_payment' | 'normal' | ''> => {
    setSaveConfig('/api/v1/hgba/portal/companyregister/upgrade', {
      useResponseMessage: true,
      doToast: false,
      successWithMessage: true
    });
    await saveData({ data: form });
    if (!saveError.value) {
      return '';
    }
    addToastMessage(error.value.message, 'error');
    return error.value.type;
  };

  return {
    postXbaCompanyRegisterApproved,
    postXbaCompanyRegisterRejected,
    postHgbaPortalCompanyRegisterRejected,
    postXbaCompanyUnsubscribe,
    postHgbaPortalOnlyUnsubscribe,
    postCompanyCreateAll,
    getXbaSettingPermissions,
    postAichatBaCompanyNeeds,
    getAichatBaCompanyNeedsWritingStyle,
    getXbaUpgradeStatus,
    postHgbaCompanyRegisterUpgrade
  };
};
