

















import {
  defineComponent,
  ref,
  useMeta,
  useContext
} from '@nuxtjs/composition-api';
import { provideStore, useStore } from '@/compositions/store';
import { appStore, masterStore, userStore } from '@/store';
import { getBamAppConfig } from '@/apis/config';
import service from '@/utils/api';
import { isKKPPFromStrategy } from '@/utils/kkpp';

export default defineComponent({
  setup() {
    // provideにキー付きでリアクティブな状態を登録(各コンポーネントで利用可能となる)
    provideStore();

    const { title, meta } = useMeta();
    const ctx = useContext();
    const dialog = ref<boolean>(false);
    const initialized = ref<boolean>(false);

    const { breadcrumb, overlay, setOverlay } = useStore();

    setOverlay(true);

    // システム定義/カスタマイズ項目取得一時的な対応（サーバーサイドのマルチテナント化完了まで）
    getBamAppConfig()
      .then((r: any) => {
        if (r && r.result && r.result.api_url) {
          appStore.SET_BASE_URL(r.result.api_url);
        } else if (process.env.NAME === 'DEVELOPMENT' || process.env.NAME === 'STAGING' || process.env.NAME === 'PRODUCTION') {
          ctx.$sentry.captureMessage('サーバ情報が取得特できませんでした');
          return;
        } else {
          appStore.SET_BASE_URL(process.env.API_URL || '');
        }
        service.defaults.baseURL = appStore.baseUrl;
      })
      .then(() => {
        Promise.all([
          appStore.loadConfig(),
          userStore.loadUser()
        ]).then((r) => {
          if (!r[0]) {
            // ctx.$sentry.captureMessage('設定ファイルが読み込めませんでした');
            // ctx.error({
            //   statusCode: 401,
            //   message: '設定ファイルが読み込めませんでした'
            // });

            // NOTE：KKPP対応
            // トークン有効期限切れの場合にここでreturnのみだとここで処理が止まり画面が真っ白になる
            // strategyがKKPPの場合は401エラー画面に遷移させる
            // L64-68のコメントアウトを外したいが経緯がわからず影響範囲が大きいのでKKPPのみ対応する
            if (isKKPPFromStrategy()) {
              ctx.error({
                statusCode: 401,
                message: '設定ファイルが読み込めませんでした'
              });
            }

            return;
          }

          meta.value = [
            {
              hid: 'robots',
              name: 'robots',
              content: 'noindex'
            }
          ];

          title.value = `${appStore.appConfig.bank.ba_name} (${appStore.appConfig.bank.name})`;

          masterStore.loadMaster().then(() => {
            initialized.value = true;
          });
          setOverlay(false);
        });
      });

    return { breadcrumb, dialog, initialized, overlay };
  },
  head: {}
});
