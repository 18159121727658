import { userStore } from '@/store';

/**
 * 金融機関事務局ユーザー判定
 */
export const isBankAdmin = (): boolean => {
  return (
    userStore.loginUser.user_type === 'system_admin' ||
    userStore.loginUser.user_type === 'bank_admin'
  );
};
